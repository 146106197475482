.background-image {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.background-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/img/parkingscreen.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.6);
  filter: brightness(50%);
  z-index: -1;
}







.logo-container {
  position: absolute;
  top: 60px;
  left: 16px;
}

.logo-image {
  width: 118px;
  height: 40px;
}

.text-container {
  position: absolute;
  width: 296px;
  height: 80px;
  top: 580px;
  left: 16px;
  display: flex;
  align-items: flex-end;
}

.text {
  font-family: 'SF Pro Display', sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #ffffff;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  gap: 10px;
  width: 100%;
  padding: 0 16px;
}

.get-started-button,
.login-button {
  width: 99%;
  height: 52px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'SF Pro Display', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  transition: transform 0.2s;
}

.get-started-button {
  background-color: #005E25;
  color: #ffffff;
}

.login-button {
  background-color: #FFFFFF;
  color: #000000;
}


.get-started-button:active,
.login-button:active {
  transform: scale(0.95);
}



/* Media Queries */
@media (max-width: 992px) {
  .text-container {
    top: 577px;
  }
}

@media (max-width: 812px) {
  /* iPhone X and upward */
  .text-container {
    top: 220px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
  

  .button-container {
    width: 90%;
  }

  .text-container {
    margin-bottom: 30px;
  }
}


@media (max-width: 576px) {
  .text-container {
    top: 437px;

  }

  .text {
    font-size: 34px;
    line-height: 40px;
  }
}

@media (max-width: 360px) {
  .text-container {
    top: 437px;
  }

  .text {
    font-size: 20px;
    line-height: 26px;
  }
}
