
.book-ab {
    padding: 15px;
    background-color: #1c8e43;
    width: 100%;
    height: 54px;
    color: #ffff;
    border-radius: 50px;
    margin-top: 10PX;
}


.input-places {
    /* position: relative;
    top: 10%; */
    padding: 10px;
    width: 100%;
    z-index: 1;
}

.input-places:focus {
    outline: none;
}
.parks-search {
    background-color: transparent;
    padding: 10px;
    margin-left: 1%;
    margin-right: 1%;
    position: absolute;
    top: 3%;
    z-index: 1;
    width: 100%;
    border-radius:4px;
}

.parks-searches {
    background-color: white;
    padding: 10px;
    margin-left: 5%;
    margin-right: 5%;
    position: absolute;
    
    z-index: 1;
    width: 90%;
    border-radius:4px;
}
.slider {
    margin-top: -50%; /* the value of this should be reset to -60% when the promo banner is removed. */
}
.banner {
    margin-top: -90%;
}
.gm-fullscreen-control {
    display: none;
}
.gmnoprint {
    display: none;
}
.input-home {
    padding: 10px;
    position: relative;
    width: 100%;
}
.input-home:focus {
    outline: none;
}
ion-slides {
    height: 100%;
  }
  .home-titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
  }

  .navigation-arrow {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .plate-number input {
      padding: 10px;
      width: 100%;
      background-color: aliceblue;
  }

  .plate-number input:focus {
      outline: none;
  }


  ion-searchbar.custom {
    --background: #e9e9e9;
    --color: #767680;
    --placeholder-color: #767680;
    --icon-color: #767680;
    --clear-button-color: #767680;;
  
    --border-radius: 14px;
  }
  
  ion-searchbar.ios.custom {
    --cancel-button-color: #19422d;
  }
  
  ion-searchbar.md.custom {
    --cancel-button-color: #767680;;
  }

  @media (max-width: 375px) {
    .slider {
      margin-top: -50%;
    }
  }
  
  /* Rest of the styles in Home.css... */

  .bottom-sheet {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    transition: top 0.3s ease-in-out;
    overflow-y: auto;
  }
  
  .bottom-sheet-content {
    padding: 16px;
  }
  
  /* Optional: Add styling for the close button */
  .bottom-sheet-close {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  