@font-face {
  font-family: 'Gilroy-Bold';
  src: url('/assets/fonts/Gilroy-Bold.ttf');
  font-style: bold;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('/assets/fonts/Gilroy-Medium.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('/assets/fonts/Gilroy-Regular.ttf');
  font-style: normal;
}
