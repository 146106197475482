
.search-container {
    background-color: #1c8e45;

    margin-top: -4%;
    padding-top: 5%;
    padding-left: 2%;
    padding-right: 2%;
    overflow: scroll;
}

.search-container h3 {
    font-size: 40px;
    color: white;
}

.search-container h4 {
    font-size: 30px;
    color: white;
}

.result{
    color: white;
}
.abd {
    display: flex;
    align-items: center;
    border-bottom: 1px solid white;
}
.abd input {
    background-color: transparent;
    border: none;
    color: white;
}

.abd input::placeholder {
    color: white;
}

.search-item {
    background-color: transparent;
    margin-bottom: 2%;
    padding: 10px;
border: 2px solid #f2f2f2;
border-radius: 12px;
}

.pic-item {
    background-color: transparent;
    margin-bottom: 2%;
    padding: 10px;

}

.no-park {
    text-align: center;
    margin-top: 20%;
}

ion-img.crashimg {
    margin: 0 auto;
    width: 35%;
    height: 35%;
}