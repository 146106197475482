.wrapper {
  width: 100%;

}

.slide-item {
  background-color: white;
  width: 500px;
  height: 175px;
  margin: 3%;
 
  padding: 10px;
  border-radius: 3%;
}

.slide-item2 {
  background-color: white;
  width: 500px;
  height: 175px;
  margin: 3%;
  padding: 10px;
  border-radius: 3%;
}
.slide-item2 img {
  margin-top: -10%;
}
.slide-item2 h6 {
  margin-top: -10%;
}

.item-div1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
}
.item-div1 div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 3%;
  margin-top: -5%;
  width: 100%;
}
.item-div1 img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.status-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.verified {
  background-color: #4ba76c;
  color: white;
  font-size: 10px;
  border-radius: 5px;
}

.nt-verified {
  background-color: #ec931e;
  color: white;
  font-size: 10px;
  border-radius: 5px;
}

.status-check button {
  padding: 10px;
  margin-top: -15%;
}
.item-div1 h2 {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: start;
}
.item-div1 p {
  font-size: 12px;
  margin-top: -4%;
  text-align: start;
}

.item-div2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-div2 p {
  font-size: 11px;
}

.item-div2 h6 {
  font-size: 15px;
  font-weight: bold;
  margin-top: -5%;
}

.item-div2 button {
  padding: 10px;
  border: 1px solid #4ba76c;
  background-color: transparent;
}

.park-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
}
.park-detail h1 {
  font-size: 22px;
}
.detail-container img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.detail-content {
  padding: 0px 5% 10px 5%;
}
.active-category {
  background-color: #4ba76c;
  color: white;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  border: none !important;
  padding: 11px !important;
}

.cate {
  font-size: 15px;
  border: 1px dashed blue;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  text-align: center;
  padding: 10px;
}

.care {
  font-size: 15px;
  border: 1px dashed blue;

  text-align: center;
  padding: 10px;
}
.plan {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #4ba76c;
  margin-bottom: 2%;
  border-radius: 13px;
  color: white;
}
.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: white;
}
.select {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: rgb(100, 20, 204);
}
.detail-span {
  margin-left: 1px;
  font-size: 16px;
}
.detail-icon {
  font-size: 42px;
  color: #005E25;
}
.book-div {
  margin: 3% 5%;
}
.payments-btn {
  display: flex;
  justify-content: space-between;
}
.book-m {
    padding: 15px;
    background-color: #E6E6E6;
    width: 100%;
    height: 54px;
    color: #808080;
    border-radius: 50px;
    margin-top: 10PX;
}
.book-n {
  padding: 15px;
  background-color: #4ba76c;
  width: 100%;
  height: 54px;
  color: white;
  border-radius: 50px;
  margin-top: 10PX;
}
.book {
  padding: 15px;
  background-color: black;
  width: 45%;
  color: white;
}
.fund {
  padding: 15px;
  background-color: #4ba76c;
  width: 45%;
  color: white;
}

.detail-verified {
  background-color: #4ba76c;
  color: white;
  padding: 10px;
  font-size: 10px;
  width: 30%;
  text-align: center;
  margin: 2% 5%;
  border-radius: 5px;
}
.card-details {
  padding: 10px;
}
.detail-nt-verified {
  background-color: #ec931e;
  border-radius: 5px;
  color: white;
  font-size: 10px;
  padding: 10px;
  margin: 2% 1%;
  width: 30%;
  text-align: center;
}


@media (min-width: 1000px) { 
  .item-div1 img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  .item-div1 h2 {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: start;
    margin-bottom: 22px;
  }
  .item-div1 p {
    font-size: 12px;
    
    text-align: start;
  }

  .item-div1 {
    display: flex;
    
    align-items: center;
    width: 80%;
    background-color: blue;
   
   
  }
  .item-div1 div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 1%;
    margin-top: 1%;
    width: 40%;
    background-color: #4ba76c;
  }
}


ion-badge.detail-badge {
  --background: #CCDFD3;
  --color: black;
  --padding-end: 20px;
  --padding-start: 20px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  border-radius:20px;
}