.flag-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    margin-right: 0.5em;
  }
  
  .flag-icon-us {
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/us.svg');
  }
  
  .flag-icon-uk {
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/gb.svg');
  }
  
  .flag-icon-in {
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/in.svg');
  }
  
  
ion-select ion-select-option .flag-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  margin-right: 0.5em;
}
  /* Add more flag classes here for other countries */
  

  /* Style for PhoneInput component */

.ReactPhoneInput__icon {
  font-size: 1.5em;
}

.ReactPhoneInput__input {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    width: 100%;
    height: 2.5rem;
    background-color: #fff;
    margin-bottom: 0.5rem;
  }
  
  .ReactPhoneInput__input:focus {
    outline: none;
    border-color: #3880ff;
    box-shadow: 0 0 0 2px rgba(56, 128, 255, 0.2);
  }
  .phone-input-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    /* height: 48px; */
    padding: 0 16px;
    /* border-radius: 4px; */
    /* border: 1px solid #c4c4c4; */
    /* background-color: transparent; */
    margin-bottom: 2px;
    color: #000;
    border: 2px solid #E6E6E6;
    border-radius: 99px;
    height: 54px;
    background-image: linear-gradient(0deg, #e6e6e6, #e6e6e6),
      linear-gradient(0deg, #f2f2f2, #f2f2f2);
  
  }
  
  .phone-input-container input {
    flex: 1;
    font-size: 16px;
    line-height: 1.5;
    border: none;
    outline: none;
    background-color: transparent;
    color: inherit;
    padding: 0;
    margin: 0;
  }
  
  .phone-input-container input::placeholder {
    color: black;
  }
  
  /* Laptop styles */
@media screen and (min-width: 1024px) {
  .phone-input-container {
    /* Adjust styles for laptop screens here */
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    /* height: 48px; */
    padding: 0 16px;
    /* border-radius: 4px; */
    /* border: 1px solid #c4c4c4; */
    /* background-color: transparent; */
    margin-bottom: 2px;
    color: #000;
    border: 2px solid #E6E6E6;
    border-radius: 99px;
    height: 54px;
    background-image: linear-gradient(0deg, #e6e6e6, #e6e6e6),
      linear-gradient(0deg, #f2f2f2, #f2f2f2);
  }
}