ion {
    &-item {
      --padding-start: 0px;
    }
    &-grid {
      --ion-grid-padding: 0px;
    }
    &-row {
      margin-left: -5px;
      margin-right: -5px;
    }
  }
  .action-container {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px; 
    font-family: "Gilroy-medium";
  }
    .reg {
      color: green;
      font-size: 13px;
    }
    .reg-f {
      color: green;
      font-size: 13px;
      margin: 0 auto;
    }
    .reset {
      color: red;
      font-size: 13px;
    }
  
  
  .auth {
    &__form {
      display: flex;
      flex-direction: column;
      ion-item {
        margin-bottom: 15px;
      }
      &__link {
        margin-top: 40px;
        margin-bottom: 35px;
        font-size: 14px;
      }
      ion-button {
        height: 50px;
        font-size: 16px;
      }
    }
    &__title {
      h3 {
        margin-bottom: 7px;
      }
    }
    &__subtitle {
      font-size: 17px;
      color:#808080;
    
      
    }
  }
  
  .logo-container {
    position: absolute;
    top: 60px;
    left: 16px;
  }

  .logo-image {
    width: 118px;
    height: 40px;
  }

.bankAccountName{
  font-size: 15px;
  margin: 10px 0px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}


ion-select ion-select-option .flag-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  margin-right: 0.5em;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 300px;
  height: 38px;
  margin: 10px 0;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.select-wrapper select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.select-wrapper label {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #333;
  z-index: 2;
  pointer-events: none;
}

.select-wrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  pointer-events: none;
  z-index: 2;
}

.select-wrapper:focus-within {
  border-color: #007aff;
  box-shadow: 0px 0px 3px rgba(0, 122, 255, 0.4);
}

.select-wrapper:focus-within label {
  color: #007aff;
}

.select-wrapper:focus-within::after {
  border-color: #007aff;
}

ion-button.l-button {
  --background: #E6E6E6;
  --background-active: #E6E6E6;
  --background-focused: #E6E6E6;
  --background-activated-opacity: #E6E6E6;
  --background-focused-opacity: #E6E6E6;
  --color: #808080;
font-family: "gilroy-medium";

  --border-color: #E6E6E6;
  --border-style: solid;
  --border-width: 2px;
  transition: transform 0.2s;
  transform: scale(0.95);
}

ion-button.la-button {
  --background: #4ba76c;
  --background-active: #4ba76c;
  --background-focused: #4ba76c;
  --background-activated-opacity: #4ba76c;
  --background-focused-opacity: #4ba76c;
  --color: #ffff;
font-family: "gilroy-medium";


  transition: transform 0.2s;
  transform: scale(0.95);
}

ion-button.g-button {
  --background: #E6E6E6;
  --background-active: #E6E6E6;
  --background-focused: #E6E6E6;
  --background-activated-opacity: #E6E6E6;
  --background-focused-opacity: #E6E6E6;
  --color: black;
font-family: "gilroy-medium";
font-size: 0.875rem;
font-weight: 600;
  // --border-color: #E6E6E6;
  // --border-style: solid;
  // --border-width: 2px;
  --border-radius: 23px;
  transition: transform 0.2s;
  transform: scale(0.95);
}

ion-button.i-button {
  --background: black;
  --background-active: black;
  --background-focused: black;
  --background-activated-opacity: black;
  --background-focused-opacity: black;
  --color: white;
font-family: "gilroy-medium";
font-size: 1rem;
font-weight: 600;
  --border-radius: 23px;
  // --border-color: #E6E6E6;
  // --border-style: solid;
  // --border-width: 2px;
  transition: transform 0.2s;
  transform: scale(0.95);
}

ion-button.n-button {
  --background: #E6E6E6;
  --background-active: #E6E6E6;
  --background-focused: #E6E6E6;
  --background-activated-opacity: #E6E6E6;
  --background-focused-opacity: #E6E6E6;
  --color: black;
font-family: "gilroy-medium";
font-size: 0.875rem;
font-weight: 600;
  // --border-color: #E6E6E6;
  // --border-style: solid;
  // --border-width: 2px;
  --border-radius: 23px;
  transition: transform 0.2s;
  transform: scale(0.95);
bottom: 50px;
width: 90%;
height: 56px;
align-self: center;
margin-bottom: 70px;
margin-left: -50px;
}

.next-button-container {
  position: fixed;
  bottom: 20px; /* Adjust this value to set the desired distance from the bottom */
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

div.login-card {
  width: 100%;

}

div.fl-div {
  width: 100%;  
  display: flex;
  
}

@media screen and (min-width: 768px) {
  ion-card.login-card {
    /* Adjust styles for tablet screens here */
    width: 50%;
    padding: 20px;
  }
}

/* Laptop styles */
@media screen and (min-width: 1024px) {
  div.login-card {
    /* Adjust styles for laptop screens here */
    width: 50%;
    padding: 20px;
    border: 2px solid #E6E6E6;
    border-radius: 12px;
    margin: 0 auto;
  }

  .logo-container {
    position: absolute;
width:100%;
    top: 60px;
  left: 40%;
  transform: translateX(50%);
  }

  .next-button-container {
    position: fixed;
    bottom: 20px; /* Adjust this value to set the desired distance from the bottom */
    width: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

/* Desktop and above styles */
@media screen and (min-width: 1440px) {
  ion-card.login-card {
    /* Adjust styles for desktop and above screens here */
    width: 50%;
    padding: 20px;
  }
}