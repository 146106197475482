

.success-container {
    background-color: #1c8e45;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.success-container p {
    color: white;
    font-size: 18px;
}