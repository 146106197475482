/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/react/css/normalize.css';
@import '~@ionic/react/css/structure.css';
@import '~@ionic/react/css/typography.css';
@import '~@ionic/react/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/react/css/padding.css';
@import '~@ionic/react/css/float-elements.css';
@import '~@ionic/react/css/text-alignment.css';
@import '~@ionic/react/css/text-transformation.css';
@import '~@ionic/react/css/flex-utils.css';

body {
  color: #292929;
  background-color: #ffffff;
}

a {
  text-decoration: none;
}

.modal {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0;
    h3 {
    
      font-family: "Inter";
font-size: 1.5rem;
font-weight: 600;
line-height: 1.75rem;
letter-spacing: -0.04em;
text-align: left;

    }
    ion-icon {
      margin-right: -9px;
      
    }
  }
}


.paystack {
  background-color: #005E25;
  color: white;
  font-size: 18px;
  border-radius: 45px;
  // border-top-left-radius: 10px ;
  // border-top-right-radius: 10px ;
  // border-bottom-left-radius: 10px ;
  // border-bottom-right-radius: 10px ;
  width: 100%;
  padding: 5%;
  letter-spacing: 0.8px;
  font-family: 'gilroy-regular';
}


.auth {
  &__form {
    display: flex;
    flex-direction: column;
    ion-item {
      margin-bottom: 15px;
    }
    &__link {
      margin-top: 40px;
      margin-bottom: 35px;
      font-size: 14px;
    }
    ion-button {
      height: 50px;
      font-size: 16px;
    }
  }
  &__title {
    h3 {
      margin-bottom: 7px;
    }
  }
  &__subtitle {
    font-size: 17px;
    color:#808080;
  
    
  }
}


.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}


ion-button.carbon {
 
  --background: #005E25;
  --background-active: #005E25;
  --background-focused: #005E25;
  --background-activated-opacity: #005E25;
  --background-focused-opacity: #005E25;
  letter-spacing: 0.8px;
}
.selected-park {
  border: 2px solid #005E25; /* Green border */
}

ion-modal#transfer-modal {
  --height: 50%;
  --width: 94vw;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#transfer-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-modal#transfer-modal ion-toolbar {
  --background: #1c8e45;
  --color: white;
}

ion-button.cost-button {

  border-radius: 15px;
 padding-left: 12px;
  height: 3rem;
  font-size: 14px;
  font-family: "gilroy-bold";
}

 .ce-message-form-input {
  background-color: #E6E6E6!important;
  height: 44px!important;
  border-radius: 13px;
  
}

.ce-message-form {
  margin-left: 14px!important;
}

.ce-default-button{
  color:#005E25!important;
  border-color: #005E25!important;
}

.ce-chat-header-title {
  color:#005E25!important;
}
.ce-chat-header-subtitle {
  color:#000000!important;
}

.ce-input {
  background-color: #E6E6E6!important;

}

.ce-autocomplete-input {
  background-color: #E6E6E6!important;
}
.ce-my-message-body {
  background-color: #005E25!important;
}

#ce-send-message-button {
  background-color: #005E25!important;
}



