.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .logo-icon {
    width: 100px; /* Adjust the size of the logo icon as needed */
    height: 100px;
  }
  
  .spinner {
    margin-top: 16px; /* Adjust the margin as needed */
  }
  