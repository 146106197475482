.auth {
    &__form {
      &__link {
        text-align: center;
        margin-top: 20px;
      }
    }
  }
  
  .otp {
    display: flex;
    border-radius: 5px;
    background-color: #ffffff;
    height: 75px;
    padding: 5px 0;
    margin: 20px 0 36px;
    input {
      width: 25%;
      background: transparent;
      height: 100%;
      border: none;
      text-align: center;
      font-size: 30px;
      outline: none;
      &:not(:last-child) {
        border-right: 1px solid var(--ion-color-medium);
      }
    }
    &-number {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 10px;
      }
    }
  }
  